@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Poppins:wght@700&display=swap');

:root {
  --primary: #7752FE;
  --secondary: #8E8FFA;
  --green: #D8FFB0;
  --blue: #B0CBFF;
  --red: #FFB0B0;
  --yellow: #FFEEB0;
  --black: #282828;
  
  
  --text-primary: #282828;
  --text-secondary: rgba(87, 87, 87, 0.72);
  --text-tertiary: rgba(40, 40, 40, 0.72);
  --text-quaternary: rgba(30, 30, 30, 0.50);
  --background: white;
  --background-secondary: rgba(0, 0, 0, 0.08);
  --background-tertiary: #F7F8F8;
  --border: rgba(40, 40, 40, 0.24);
}

:root[data-theme="dark"] {
  --black: white;
  --text-primary: white;
  --text-secondary: rgba(255, 255, 255, 0.72);
  --text-tertiary: rgba(255, 255, 255, 0.72);;
  --text-quaternary: white;
  --background: black;
  --background-secondary: rgba(255, 255, 255, 0.08);
  --background-tertiary: #141414;
  --border: rgba(255, 255, 255, 0.24);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--background);
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button {
  text-decoration: none;
  border-radius: 100px;
  background: #7752FE;
  color: white;
  padding: 16px 44px;
  font-weight: 900;
  font-size: 1.25rem; /* 16px */
}

.form-control {
  display: block;
  width: 100%;
  border-radius: 1rem;
  background: var(--background-secondary);
  padding: 1.5rem 2rem;
  color: var(--text-quaternary);
  outline: none;
}

.form-control:not(:last-child) {
  margin-bottom: 0.5rem;
}

.form-control::placeholder {
  color: var(--text-tertiary);
  font-size: 1rem;
  font-weight: 700;
}