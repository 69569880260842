#root > header {
  background: linear-gradient(180deg, #EEEDED 27.55%, rgba(255, 255, 255, 0.00) 100%);
}

@media (min-width: 1280px) {
  #root > header {
    min-height: 100vh;
  }
}

html[data-theme="dark"] #root > header {
  background: linear-gradient(180deg, #171717 27.55%, rgba(13, 13, 13, 0.00) 100%);
}