nav ul li a {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-primary);
}

nav ul li a.active {
  color: white;
  background-color: var(--primary);
  padding: 10px 18px;
  border-radius: 12px;
  line-height: normal;
}